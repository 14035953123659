export const changeIframe = (action, properties) => {
  const dataToSend = {
    messageOrigin: "vidjet",
    origin: action,
    properties: properties,
  };
  window.parent.postMessage(dataToSend, "*");
}

export const displayCurrency = (currency, price) => {
  if (!currency || currency === "USD") {
    return `$ ${price}`;
  } else if (currency === "EUR") {
    return `${price} €`;
  } else if (currency === "NOK") {
    return `Kr ${price}`
  } else if (currency === "GBP") {
    return `£ ${price}`
  } else {
    return `${price} ${currency}`;
  }
};


export const decodeText = (text) => {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
};

export const deepClone = obj => {
  if (obj === null) return null;
  if (typeof obj !== 'object') return obj;

  return Array.isArray(obj)
    ? obj.map(item => deepClone(item))
    : Object.entries(obj).reduce((copy, [key, value]) => ({ ...copy, [key]: deepClone(value) }), {});
}
